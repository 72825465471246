.iSAVrt {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    color:white !important;
    background-color: #009ef7 !important;
    min-height: 52px;
    border-bottom-color: rgba(0,0,0,.12);
    border-bottom-style: solid;
}
.dOIzqm {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 95% !important;
    margin: auto !important;
}
.dwPlXY:nth-child(odd) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0,0,0,0.87);
    background-color: #f2f2f2 !important;
    min-height: 48px;
}
.dwPlXY:nth-child(even) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0,0,0,0.87);
    background-color: #fff !important;
    min-height: 48px;
}

.fyrdjl:disabled {
    cursor: unset;
    color: white !important;
    fill: white !important;
}
.fyrdjl {
    cursor: unset;
    color: white !important;
    fill: white !important;
}

.dtqzBx {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px !important;
}
.hbotQl {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px !important;
    font-weight:700 !important
}

.drXdhv {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    background-color: #009ef7 !important;
    min-height: 52px;
   
}


.coFHkF {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    color: white !important;
    font-size: 13px;
    font-weight: 500;
    border-left:10px solid black !important;
    border-right:10px solid black !important;
    border-radius: 10px !important;
}

.bviTYl {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width:98% !important;
    margin:auto !important;
    text-align: center !important;
}

.bviTYl {
    display: flex;
    flex-direction: column;
    width: 90% !important;
    margin: auto !important;
    text-align: center !important;
}

.cxIebV:nth-child(even) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 600;
    color:black;
    background-color: #f2f2f2 !important;
    min-height: 48px;
}
.cxIebV:nth-child(odd) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 600;
    color:black;
    background-color: #fff !important;
    min-height: 48px;
}

.lmJygF {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
    padding-right: 8px;
    padding-left: 8px;
    width: 100%;
    color: white !important;
      font-size: 13px;
    min-height: 56px;
    background-color: #009ef7 !important;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: rgba(0,0,0,.12);
    border-left:10px solid black !important;
    border-right:10px solid black !important;
    border-radius: 10px !important;
}
.btnGmk:disabled {
    cursor: unset;
    color: white !important;
    fill: white !important;
}
.btnGmk {
    cursor: unset;
    color: white !important;
    fill: white !important;
}


.jrEKsr {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    width: 100%;
    background-color: #009ef7 !important;
    min-height: 52px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px !important;
    border-right: 10px solid black !important;
    border-left: 10px solid black !important;
}
.kmscRy{
    color:white !important
}
.WZfqd {
    display: flex;
    flex-direction: column;
    width: 98% !important;
    margin: auto !important;
}
.bQwgPr {
    display: flex;
    flex: 1 1 auto;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    padding-right: 8px;
    padding-left: 8px;
    width: 100%;
    color: white !important;
    font-size: 13px;
    min-height: 56px;
    background-color: #009ef7 !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px !important;
    border-left: 10px solid black !important;
    border-right: 10px solid black !important;
}

.kSrFOW:disabled {
    cursor: unset;
    color: white !important;
    fill: white !important;
}
.kSrFOW {
    cursor: unset;
    color: white !important;
    fill: white !important;
}

