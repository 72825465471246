.App {
  text-align: center;
  background-color: #51bbb6;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.red{
  background-color: #CC3C3C;
}
.green{
  background-color: #26B54E;
}
.blue{
  background-color: #2F8FE8;
}
.blue-fad{
  background-color: #38ABC5;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.answers {
  display: 'flex';
  background-color: #f2f2f2;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.box {
  height: 300;
  width: '49%';
  align-items: 'center';
  justify-content: 'center';
  display: 'flex';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}



/* new code start heerer */
.quizgamepin{
  width:20%;
  height:110px;
  border-radius:5px;
  cursor:pointer;
  background-color:#fff;
  padding: 15px
}
.quizgamepin input{
  width:90%;
  height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight:bolder;
}
.quizgamepin button{
  width:90%;
  height: 40px;
  text-align: center;
  background-color: #2F2F2F;
  color:#fff;
  font-size: 20px;
  font-weight: bolder;
  margin-top: 15px
}
.error-mess{
  margin-top: 20px; 
  color: red;
}

@media only screen and (max-width: 767px) {
.quizgamepin{
  width: 40%!important;
}
}



/* answer page css tart herere */
.time-upsss{
  display: flex;
  justify-content:center;
  align-items: center;
  /* background-color:#fff; */
  padding: 10px;
  width: 150px;
  height: 100;
  border-radius: 5px;
  font-weight:bolder;
  font-size: 30;
  color:#000;
}


/* Game-start cssss start hererer */
.Game-start{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  margin-left: 30px;
}
.quiz{
  display:flex;
  align-items:center;
  justify-content:center;
  height: 30px;
  width: 80px;
  border-radius: 20px;
  background-color:#F2F2F2
}
.answer-flexxing-ro{
  display:flex;
  flex-wrap: wrap;
  justify-content:space-around;
}



/* instru-heading */
.instru-heading{
  font-size: 16px;
}
.instru-errorsss{
  margin-top: 20px; 
  color:red;
}

/* join comp csss */
.quiz-join{
  width: 20%;
  height: 110px;
  border-radius: 5px;
  cursor:pointer;
  background-color:#fff;
  padding: 15px;
}
.quiz-join input{
  width:90%;
  height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
}
.quiz-join button{
  width: 90%;
  height: 40px;
 text-align: center;
  background-color:#2F2F2F;
  color:#fff;
  font-size: 20px;
  font-weight:bolder;
  margin-top: 15px
}
.error-joinpage{
  margin-top: 20px; 
  color:red; 
}

/* first-start */
.first-start{
  display: flex;
justify-content: center;
 align-items: center;
background-color: #fff;
  padding: 10px;
  width: 150px;
  height: 100px;
  border-radius: 5px;
  font-weight:bolder;
  font-size: 30px;
  color:#000;
}
.second-start{
 display: flex;
 justify-content: center;
align-items: center;
 background-color: #fff;
  padding: 10px;
  height: 60px;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 20px;
  color:#000;
}

.badgwedddddd{
  height: 200px;
}

.badged-division{
  position: relative; 
}

.main-heading-named {
  font-size: 54px;
  position: absolute;
  width: 100%;
  top: 18px;
  color: #000;
}

.quizophy{
  width: 100vw;
  height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}
.container-fluid,.quizophy_bg{
  width:100% !important;
  height: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}
.container_row{
  width: 100% !important;
  height:100% !important;
  padding: 0px !important;
  margin:0px !important;
}
.q_heading,.q_form,.q_button{
  text-align: center;
  color:white;

}
.q_heading p{
  font-size: 28px;
  font-weight: 800;
}

.q_heading p sup{
  font-size: 10px !important;
  font-weight: 500;
}
.q_heading{
  margin-top: 8px !important;
}
.q_form{
  margin: -50px auto 30px auto !important;
}
.q_button .btn{
 color:blue;
 /* font-weight: 600; */
 background-color: white;
 /* padding: 3px 25px; */
}
.q_button .btn:hover{
  color:blue;
  /* font-weight: 600; */
  background-color: white;
  /* padding: 3px 25px !important; */
}

@media only screen and (max-width:740px){
  .q_form{
    width:360px !important;
  }
}
@media only screen and (max-width:400px){
  .q_form{
    width:340px !important;
  }
}
@media only screen and (max-width:365px){
  .q_form{
    width:320px !important;
  }
}
.q_form{
  max-width: 450px !important;
  background-color: white; 
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.q_form input{
  margin: 30px auto !important;
  width: 300px !important;
  border:none;
  background-color: #f2f2f2;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.6);
}
.choose_avatar{
  width:300px !important;
}
.choose_avatar p{
  color: black
}
.css-dqr9h-MuiRating-label{
  margin-top: 5px !important;
}